import { DashAccessTokenInfo } from "../models/DashAccessTokenInfo";
import { DashAuthResult } from "../models/DashAuthResult";

export const logout = () => {
  localStorage.removeItem("auth");
};

export const getAuthResult = (): DashAuthResult | undefined => {
  const authString = localStorage.getItem("auth");
  if (authString) {
    const auth = JSON.parse(authString) as DashAuthResult;
    if (auth) {
      return auth;
    }
  }
  return undefined;
};

export const getAccessToken = (): DashAccessTokenInfo => {
  const authString = localStorage.getItem("auth");
  if (authString) {
    const auth = JSON.parse(authString) as DashAuthResult;
    const accessToken = auth.credential.oauthAccessToken;
    if (accessToken) {
      return accessToken;
    }
  }
  return undefined;
};
