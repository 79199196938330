import { DashError } from "./DashError";

export type RequiredProvider = "microsoft" | "google";

export class RequireLoginError extends DashError {
  public requiredProvider: RequiredProvider;

  constructor(provider: RequiredProvider) {
    const message = `Must be logged in with the following provider: "${provider}".`;
    super(message, undefined, "REQUIRED_LOGIN_ERROR");

    this.requiredProvider = provider;
    Object.setPrototypeOf(this, RequireLoginError.prototype);
  }
}
