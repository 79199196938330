import { VersionInfo } from "../../VersionInfo";
import style from "./DashReadableContent.module.css";
import { Icon } from "@fluentui/react/lib/Icon";
import { useHistory } from "react-router-dom";

export const DashReadableContent: React.FC = (props) => {
  const history = useHistory();
  const backIsHome = history.length === 1;
  const icon = backIsHome ? "Home" : "ChromeBack";

  return (
    <div>
      <div className={`${style.header} fixed w-screen`}>
        <div className={`${style.product_name}`}>{VersionInfo.toString()}</div>
      </div>
      <div className="fixed">
        <Icon
          iconName={icon}
          className={`${style.back_icon}`}
          onClick={() => {
            if (history.length >= 1) {
              history.goBack();
            } else {
              history.push("/");
            }
          }}
        />
      </div>
      <div className={`${style.content}`}>
        <div className={`${style.content_container} prose max-w-none`}>{props.children}</div>
      </div>
    </div>
  );
};
