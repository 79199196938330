import { DashTaskInfo, IDashTaskInfoInternal } from "../models/DashTaskInfo";
import { DashStorageTargets } from "../models/DashStorageTargets";
import { IGanttData } from "@syncfusion/ej2-react-gantt";
import { DashTaskSubTreeInfo } from "../models/DashTaskSubTreeInfo";
import produce from "immer";
import localforage from "localforage";

export class DataUtils {
  public static cleanTasks2(b: DashTaskSubTreeInfo | IDashTaskInfoInternal): void {
    delete b.Children;
    b.resourceInfo?.forEach((r) => {
      delete r.group;
      delete r.resourceName;
    });

    if (b.resourceInfo?.length === 1) {
      // ensure 100% assignment for that resource
      const resource = b.resourceInfo[0];
      resource.unit = 100;

      // check if work is string and it includes `hour` then update duration based on it
      if (typeof b.work === "string" && b.work.length > 0 && b.work.includes("1.00 hour")) {
        b.duration = 0.125;
      }

      if (typeof b.work === "string" && b.work.length > 0 && b.work.includes("hour")) {
        const str = b.work as string;
        const match = str.match(/[\d.]+/);
        if (match?.length === 1) {
          const work = match[0];
          const number = parseFloat(work);
          b.duration = number / 8;
        }
      }

      // if work is number then update duration as appropriate
      if (typeof b.work === "number" && b.work > 0) {
        b.duration = b.work / 8;
      }
    }
  }

  /**
   * Cleans tasks lists from a bloated source.
   *
   * @param tasks the tasks that are coming from a bloated source, ie, GanttComponent.
   * @returns clean `IDashTaskInfo[]`
   */
  public static cleanTasksInternal(tasks: IDashTaskInfoInternal[] | undefined): DashTaskInfo[] {
    if (tasks && tasks.length > 0) {
      return produce(tasks, (_tasks) => {
        _tasks.forEach((e) => {
          DataUtils.cleanTasks2(e);
        });
      });
    }
    return [];
  }

  /**
   * Cleans tasks lists from a bloated source.
   *
   * @param tasks the tasks that are coming from a bloated source, ie, GanttComponent.
   * @returns clean `IDashTaskInfo[]`
   */
  public static cleanTasks(tasks: IGanttData[] | undefined): DashTaskInfo[] {
    if (tasks && tasks.length > 0) {
      return produce(tasks, (_tasks) => {
        tasks.forEach((e) => {
          const b = e.taskData as DashTaskSubTreeInfo;
          DataUtils.cleanTasks2(b);
        });
        return tasks.map((e) => e.taskData) as DashTaskInfo[];
      });
    }
    return [];
  }

  public static async clearLocalStorage() {
    await localforage.clear();
  }

  public static async persist<T>(key: DashStorageTargets, data: T) {
    await localforage.setItem<T>(key, data);
  }

  /**
   * This is an object loader for objects persisted in the localstorage. A default value is provided
   * in case the localstorage is empty. If the localstorage is empty, the default value is returned while
   * saving the default value in localstorage so that the next time this is called with the same params,
   * the default value is read and returned from localstorage.
   *
   * @param key the localstorage key name.
   * @param fallback the default value to return in case the localstorage is empty.
   * @returns The `object: T`.
   */
  public static async loadObjectFromLocalStorage<T>(key: DashStorageTargets, fallback: T): Promise<T> {
    let source: T = fallback;
    const data = await localforage.getItem<T>(key);
    if (data) source = data;
    else await DataUtils.persist<T>(key, fallback);
    return source;
  }

  /**
   * This updates the current `DataManager` with information coming from the `data: T[]`.
   * @param idField This is the id or primary key field of this table.
   * @param target This is the DataManager datasource to update.
   * @param source This is the raw `T[]` data.
   */
  public static replace<T>(idField: string, target: T[], source: T[]) {
    if (target.length > 0) {
      target.length = 0; // empty the target array
      // const length = target.length;
      // for (let i = 0; i < length; i++) target.pop();
    }
    source.forEach((e) => {
      target.push(e);
    });
  }
}
