import { Component } from "../../models/Dash";
import { DashReadableContent } from "../../components/dash-readable-content/DashReadableContent";

export const DashPrivacyPolicy: Component = () => {
  return (
    <DashReadableContent>
      <h1>Privacy Policy</h1>
      <p>Last updated: August 16, 2021</p>
      <p>Please read these privacy policy carefully before using Our Service.</p>
      <h1>List of 3rd Party Integrations</h1>
      <p>
        These providers may have copies of data they collect using our website. If you refuse collection by any of these
        providers then you may opt <strong>NOT</strong> to use this web service.
      </p>
      <ul>
        <li>Microsoft Azure AD Authentication</li>
        <li>Microsoft OneDrive/SharePoint Integration</li>
        <li>Google Analytics</li>
        <li>Google Tag Manager</li>
        <li>Firebase Authentication</li>
        <li>Firebase Analytics</li>
        <li>Facebook Pixel</li>
        <li>Pingdom Realtime User Tracking</li>
      </ul>
      <h1>Things we save</h1>
      <ul>
        <li>Username</li>
        <li>Email</li>
        <li>Mouse Clicks</li>
        <li>User Activity on the App</li>
      </ul>
      <h1>Things we DON'T save</h1>
      <ul>
        <li>Your project information</li>
      </ul>
      <h1>Contact Us</h1>
      <p>If you have any questions about these Terms and Conditions, You can contact us:</p>
      <ul>
        <li>
          <p>By email: dash@netzontech.com</p>
        </li>
        <li>
          <p>
            By visiting this page on our website:
            <a href="https://dash.netzontech.com/contact" rel="external nofollow noopener">
              https://dash.netzontech.com/contact
            </a>
          </p>
        </li>
      </ul>
    </DashReadableContent>
  );
};
