import { DashResourceInfo } from "../models/DashResourceInfo";
import { DashProjectInfo } from "../models/DashProjectInfo";
import { DashHolidayInfo } from "../models/DashHolidayInfo";
import { DashTaskInfo } from "../models/DashTaskInfo";
import { DashEventInfo } from "../models/DashEventInfo";
import { addDays, addYears } from "date-fns";
import { v4 as uuidv4 } from "uuid";

/**
 * This file is temporary just for producing default Project file or equivalent to a Sample Project, Fixutre.
 */
export class AppStateDefaults {
  private static Resources: DashResourceInfo[] = [
    { id: uuidv4(), resourceName: "Martin Tamer", group: "Team 1" },
    { id: uuidv4(), resourceName: "Rose Fuller", group: "Team 1" },
    { id: uuidv4(), resourceName: "Margaret Buchanan", group: "Team 1" },
    { id: uuidv4(), resourceName: "Fuller King", group: "Team 1" },
    { id: uuidv4(), resourceName: "Davolio Fuller", group: "Team 2" },
    { id: uuidv4(), resourceName: "Van Jack", group: "Team 2" },
    { id: uuidv4(), resourceName: "Fuller Buchanan", group: "Team 2" },
    { id: uuidv4(), resourceName: "Jack Davolio", group: "Team 3" },
    { id: uuidv4(), resourceName: "Tamer Vinet", group: "Team 3" },
    { id: uuidv4(), resourceName: "Vinet Fuller", group: "Team 3" },
    { id: uuidv4(), resourceName: "Bergs Anton", group: "Team 3" },
    { id: uuidv4(), resourceName: "Construction Supervisor" },
  ];

  private static Holidays: DashHolidayInfo[] = [
    {
      id: uuidv4(),
      date: new Date("01/01/2021"),
      name: "New Year's Day",
      country: "Philippines",
      notes: "Special Non-Working Holiday",
    },
    {
      id: uuidv4(),
      date: new Date("02/12/2021"),
      name: "Chinese New Year",
      country: "Philippines",
      notes: "Special Non-Working Holiday",
    },
    {
      id: uuidv4(),
      date: new Date("02/25/2021"),
      name: "Edsa Revolution",
      country: "Philippines",
      notes: "Special Non-Working Holiday",
    },
    {
      id: uuidv4(),
      date: new Date("03/01/2021"),
      name: "Araw ng Davao",
      country: "Philippines",
      notes: "Regular Holiday",
    },
    {
      id: uuidv4(),
      date: new Date("07/01/2021"),
      name: "Holy Thursday",
      country: "Philippines",
      notes: "Regular Holiday",
    },
    {
      id: uuidv4(),
      date: new Date("07/02/2021"),
      name: "Good Friday",
      country: "Philippines",
      notes: "Special Non-Working Holiday",
    },
    {
      id: uuidv4(),
      date: new Date("07/03/2021"),
      name: "Black Saturday",
      country: "Philippines",
      notes: "Regular Holiday",
    },
    {
      id: uuidv4(),
      date: new Date("07/09/2021"),
      name: "Araw ng Kagitingan",
      country: "Philippines",
      notes: "Regular Holiday",
    },
    {
      id: uuidv4(),
      date: new Date("05/01/2021"),
      name: "Labor Day",
      country: "Philippines",
      notes: "Regular Holiday",
    },
    {
      id: uuidv4(),
      date: new Date("05/14/2021"),
      name: "Eid al-Fitr",
      country: "Philippines",
      notes: "Regular Holiday",
    },
    {
      id: uuidv4(),
      date: new Date("06/12/2021"),
      name: "Independence Day",
      country: "Philippines",
      notes: "Regular Holiday",
    },
    {
      id: uuidv4(),
      date: new Date("07/19/2021"),
      name: "Eid al-Adha",
      country: "",
      notes: "",
    },
    {
      id: uuidv4(),
      date: new Date("08/20/2021"),
      name: "Kadayawan",
      country: "Philippines",
      notes: "Special Non-Working Holiday",
    },
    {
      id: uuidv4(),
      date: new Date("08/21/2021"),
      name: "Ninoy Aquino Day",
      country: "",
      notes: "",
    },
    {
      id: uuidv4(),
      date: new Date("08/23/2021"),
      name: "Kadayawan",
      country: "Philippines",
      notes: "Regular Holiday",
    },
    {
      id: uuidv4(),
      date: new Date("08/30/2021"),
      name: "National Heroes Day",
      country: "Philippines",
      notes: "Special Non-Working Holiday",
    },
    {
      id: uuidv4(),
      date: new Date("11/01/2021"),
      name: "All Saint's Day",
      country: "Philippines",
      notes: "Special Non-Working Holiday",
    },
    {
      id: uuidv4(),
      date: new Date("11/02/2021"),
      name: "All Soul's Day",
      country: "Philippines",
      notes: "Regular Holiday",
    },
    {
      id: uuidv4(),
      date: new Date("11/30/2021"),
      name: "Bonifacio Day",
      country: "Philippines",
      notes: "Special Non-Working Holiday",
    },
    {
      id: uuidv4(),
      date: new Date("12/08/2021"),
      name: "Feast of the Immaculate Conception of Mary",
      country: "Philippines",
      notes: "Special Non-Working Holiday",
    },
    {
      id: uuidv4(),
      date: new Date("12/24/2021"),
      name: "Day Before Christmas",
      country: "Philippines",
      notes: "Regular Holiday",
    },
    {
      id: uuidv4(),
      date: new Date("12/25/2021"),
      name: "Christmas Day",
      country: "Philippines",
      notes: "Regular Holiday",
    },
    {
      id: uuidv4(),
      date: new Date("12/30/2021"),
      name: "Rizal Day",
      country: "Philippines",
      notes: "Special Non-Working Holiday",
    },
    {
      id: uuidv4(),
      date: new Date("12/31/2021"),
      name: "Last Day of the Year",
      country: "",
      notes: "",
    },
    {
      id: uuidv4(),
      date: new Date("4/8/2021"),
      name: "Bangon Kali Holiday",
      country: "",
      notes: "",
    },
  ];

  private static Events: DashEventInfo[] = [
    {
      id: uuidv4(),
      name: "Event 1 - Visible",
      timestamp: new Date(),
      description: "An event 3 days after project start",
      tag: ["high"],
      visible: true,
    },
    {
      id: uuidv4(),
      name: "Event 2 - Invisible",
      timestamp: new Date(),
      description: "An event 6 days after project start",
      tag: ["high"],
      visible: false,
    },
    {
      id: uuidv4(),
      name: "Event 3 - Visible",
      timestamp: new Date(),
      description: "An event 8 days after project start",
      tag: ["high"],
      visible: true,
    },
  ];

  public static GetDefaultProject(projectName: string, projectStartDate: Date): DashProjectInfo {
    const tasks: DashTaskInfo[] = [
      {
        id: 1,
        taskName: "Task Group",
      },
      {
        id: 2,
        taskName: "Task that takes 16 hours",
        startDate: projectStartDate,
        progress: 50,
        work: 16,
        duration: 16 / 8, // work * 60 for subtasks
        parentID: 1,
        resourceInfo: [
          {
            id: AppStateDefaults.Resources[4].id,
            unit: 100,
          },
        ],
      },
      {
        id: 3,
        taskName: "Task that takes 8 hours",
        progress: 50,
        work: 8,
        duration: 8 / 8, // work * 60 for subtasks
        parentID: 1,
        dependency: "2FS",
        resourceInfo: [
          {
            id: AppStateDefaults.Resources[3].id,
            unit: 100,
          },
        ],
      },
      {
        id: 4,
        taskName: "Task that takes 3 hours",
        progress: 50,
        work: 3,
        duration: 3 / 8,
        parentID: 1,
        dependency: "3FS",
        resourceInfo: [
          {
            id: AppStateDefaults.Resources[2].id,
            unit: 100,
          },
        ],
      },
      {
        id: 7,
        taskName: "Task that takes 7 hours",
        progress: 50,
        work: 7,
        duration: 7 / 8,
        parentID: 1,
        dependency: "2FS",
        resourceInfo: [
          {
            id: AppStateDefaults.Resources[1].id,
            unit: 100,
          },
        ],
      },
    ];

    AppStateDefaults.Events[0].timestamp = addDays(projectStartDate, 3);
    AppStateDefaults.Events[1].timestamp = addDays(projectStartDate, 6);
    AppStateDefaults.Events[2].timestamp = addDays(projectStartDate, 8);

    return {
      resources: AppStateDefaults.Resources,
      events: AppStateDefaults.Events,
      workingHours: [
        {
          id: 0,
          from: 9,
          to: 12,
        },
        {
          id: 1,
          from: 13,
          to: 18,
        },
      ],
      tasks: tasks, // start with empty for now
      holidays: AppStateDefaults.Holidays,
      meta: {
        id: uuidv4(),
        version: 1,
        name: projectName,
        projectStartDate: projectStartDate,
        projectEndDate: addYears(projectStartDate, 1),
        formatting: {
          dateTime: "MM/dd/yyyy hh:mm",
          date: "MM-dd-yyyy",
        },
      },
    };
  }
}
