import { ColumnDirective, ColumnsDirective, GridComponent, Inject } from "@syncfusion/ej2-react-grids";
import { useContext, useRef } from "react";
import { GridPrefs } from "../../consts/DashGridSettings";
import { DashProjectInfo } from "../../models/DashProjectInfo";
import { produce } from "immer";
import { DashWorkingHourInfo } from "../../models/DashWorkingHourInfo";
import { TGridActionCompleteArgs } from "../../models/TGridActionCompleteArgs";
import { AppStateContext } from "../..";
import { FC } from "../../models/Dash";

export interface DashWorkingHoursManagerProps {
  height: number;
  width: number;
  project: DashProjectInfo;
}

export const DashWorkingHoursManager: FC<DashWorkingHoursManagerProps> = (props: DashWorkingHoursManagerProps) => {
  const ref = useRef<GridComponent>(null);
  const appState = useContext(AppStateContext);
  const actionComplete = (_: TGridActionCompleteArgs) => {
    if (ref?.current?.currentViewData) {
      appState.project.next(
        produce(appState.project.getValue(), (value) => {
          if (ref?.current?.currentViewData) {
            value.event.workingHours = ref?.current?.currentViewData as DashWorkingHourInfo[];
            value.type = "change-requested";
          }
        })
      );
    }
  };
  const data = props.project.workingHours;

  return (
    <GridComponent
      id="dash-workinghours"
      key="dash-workinghours"
      enableAutoFill={true}
      showColumnMenu={true}
      allowSorting={true}
      allowPdfExport={true}
      allowReordering={true}
      allowResizing={true}
      allowMultiSorting={true}
      showColumnChooser={true}
      enableImmutableMode={true}
      allowFiltering={true}
      filterSettings={GridPrefs.filterSettings}
      allowExcelExport={true}
      height={`${props.height}px`}
      width={`${props.width}px`}
      editSettings={GridPrefs.editOptions}
      toolbar={GridPrefs.toolbarOptions}
      dataSource={data}
      selectionSettings={GridPrefs.selectionSettings}
      ref={ref}
      toolbarClick={(e) => GridPrefs.toolbarClick(ref.current, e)}
      actionBegin={(e) => GridPrefs.actionBegin(e)}
      actionComplete={actionComplete}
    >
      <ColumnsDirective>
        <ColumnDirective headerText="Id" field="id" width="120" textAlign="Right" isPrimaryKey={true} visible={false} />
        <ColumnDirective headerText="From" field="from" editType="numericEdit" />
        <ColumnDirective headerText="To" field="to" editType="numericEdit" />
      </ColumnsDirective>
      <Inject services={GridPrefs.services} />
    </GridComponent>
  );
};
