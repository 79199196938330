import { BehaviorSubject, filter, Subject, Subscription } from "rxjs";
import { parse, stringify } from "ejson";
import { IDashTaskInfoInternal } from "../models/DashTaskInfo";
import { firebaseConfig } from "../consts/firebase";
import { DashProjectInfo } from "../models/DashProjectInfo";
import { DashProjectMetaInfo } from "../models/DashProjectMetaInfo";
import { DataUtils } from "../utils/DataUtils";
import { ImportExportUtils } from "../utils/ImportExportUtils";
import { DashMenuState, MenuClickEvent } from "../components/dash-menu/DashMenuState";
import { DashEvent } from "../models/DashEvent";
import { DashShellInfo } from "../models/DashShellInfo";
import firebase from "firebase/app";
import domtoimage from "dom-to-image";
import "firebase/auth";
import { AppStateLoader } from "./AppStateLoader";
import { produce } from "immer";
import localforage from "localforage";
import { DashShellState } from "../models/DashShellState";
import { getAuthResult, logout } from "../utils/AuthUtils";
import { DashError } from "../errors/DashError";
import { AppStateManagerLog } from "../models/AppStateManagerLog";

export class AppStateManager {
  public shell: BehaviorSubject<DashEvent<DashShellInfo>>;
  public auth: Subject<void>;
  public project: BehaviorSubject<DashEvent<DashProjectInfo>>;
  public router: BehaviorSubject<DashEvent<string | undefined>>;
  public errors: BehaviorSubject<DashEvent<Error | undefined>>;

  public log: AppStateManagerLog = {
    auth: [],
  };

  private onDownloadSubscription: Subscription;
  private onExportPng: Subscription;
  private onOpenSubscription: Subscription;
  private onResetProjectDataRequest: Subscription;
  private onProjectChanged: Subscription;

  constructor(
    public dashMenuState: DashMenuState,
    private importExportUtils: ImportExportUtils,
    private appStateLoader: AppStateLoader
  ) {
    //#region Set local defaults
    this.auth = new Subject<void>();

    this.shell = new BehaviorSubject<DashEvent<DashShellInfo>>({
      type: "default",
      event: this.appStateLoader.default().shell,
    });

    this.project = new BehaviorSubject<DashEvent<DashProjectInfo>>({
      type: "default",
      event: this.appStateLoader.default().project,
    });

    this.router = new BehaviorSubject<DashEvent<string | undefined>>({
      type: "default",
      event: undefined,
    });

    this.errors = new BehaviorSubject<DashEvent<Error | undefined>>({
      type: "default",
      event: undefined,
    });
    //#endregion

    //#region Subscribe to global events
    this.onProjectChanged = this.project.pipe(filter((e) => e.type === "change-requested")).subscribe((e) => {
      this.persist(e.event)
        .then()
        .catch((e) => {
          console.error(e);
        });
    });

    this.onResetProjectDataRequest = this.dashMenuState.events.menuClick
      .pipe(filter((e) => e.id === "reset-project-data-request"))
      .subscribe(() => {
        this.setShellState("loading");
        const tasks: Promise<void>[] = [
          "current.meta",
          "current.holidays",
          "current.events",
          "current.resources",
          "current.tasks",
          "current.workingHours",
        ].map((e) => localforage.removeItem(e));
        Promise.all(tasks)
          .then(() => {
            this.setShellState("ready");
          })
          .catch((e) => {
            this.setShellState("ready");
            console.error(e);
          });
      });

    this.onOpenSubscription = this.dashMenuState.events.menuClick
      .pipe(filter((e) => e.parent === "open"))
      .subscribe(this.onOpenEvent);

    this.onDownloadSubscription = this.dashMenuState.events.menuClick
      .pipe(filter((e) => e.parent === "save"))
      .subscribe(this.onSaveEvent);

    this.onExportPng = this.dashMenuState.events.menuClick
      .pipe(filter((e) => e.id === "export-png"))
      .subscribe(this.onExportPngEvent);

    //#endregion
  }

  public toggleSidebar(visible: boolean) {
    const shellState = produce(this.shell.getValue(), (v) => {
      v.event.sidebarOpen = visible;
      v.type = "changed";
    });
    localStorage.setItem("dash-sidebar", shellState.event.sidebarOpen ? "open" : "closed");
    this.shell.next(shellState);
  }

  public stop() {
    this.onDownloadSubscription.unsubscribe();
    this.onExportPng.unsubscribe();
    this.onOpenSubscription.unsubscribe();
    this.onProjectChanged.unsubscribe();
    this.onResetProjectDataRequest.unsubscribe();
  }

  //#region Define event handlers
  private onOpenEvent = (e: MenuClickEvent) => {
    switch (e.id) {
      case "on-open-file":
        this.importExportUtils
          .open()
          .then((content) => this.import(content))
          .catch((err) => this.addError(err));
        break;
      case "on-open-onedrive":
        this.importExportUtils
          .openOneDrive()
          .then((content) => this.import(content))
          .catch((err) => this.addError(err));
        break;
    }
  };

  private onSaveEvent = async (e: MenuClickEvent): Promise<void> => {
    const exported = await this.export();
    const filename = this.project.getValue().event.meta.name;
    switch (e.id) {
      case "on-save-file":
        this.importExportUtils.saveToFile(exported, filename);
        break;
      case "on-save-onedrive":
        this.importExportUtils
          .saveToOneDrive(exported, filename)
          .then()
          .catch((err) => this.addError(err));
        break;
    }
  };

  private onExportPngEvent = () => {
    const body: HTMLElement | null = document.querySelector("#dash-body-container");
    if (body) {
      domtoimage
        .toPng(body)
        .then((dt) => {
          this.importExportUtils.downloadDataUrl(dt, this.project.getValue().event.meta.name, ".png");
        })
        .catch((err) => this.addError(err));
    }
  };

  //#endregion

  /**
   * This Persists a `TProjectInfo` in to the database and updates in memory cache.
   * @param text the EJSON serialized `TProjectInfo` object
   */
  private async import(text: string): Promise<void> {
    const data = parse(text) as DashProjectInfo;
    await this.persist(data);
  }

  /**
   * Persists a `TProjectInfo` to `localstorage`.
   *
   * @param data The `TProjectInfo` to persist in to `localstorage`.
   */
  private async persist(data: DashProjectInfo): Promise<void> {
    const tasks = DataUtils.cleanTasksInternal(data.tasks as IDashTaskInfoInternal[]);
    // TODO: ensure to create `jsonschema` validator at this point.
    await DataUtils.clearLocalStorage();
    await DataUtils.persist("current.tasks", tasks);
    await DataUtils.persist("current.meta", data.meta);
    await DataUtils.persist("current.holidays", data.holidays);
    await DataUtils.persist("current.events", data.events);
    await DataUtils.persist("current.resources", data.resources);
    await DataUtils.persist("current.workingHours", data.workingHours);

    this.project.next({
      type: "changed",
      event: {
        meta: data.meta,
        tasks: data.tasks,
        workingHours: data.workingHours,
        holidays: data.holidays,
        resources: data.resources,
        events: data.events,
      },
    });
  }

  /**
   * The `err` will be sent to the Dash outer layer DashShell so that it can analyze what to do with the
   * Shell with this error. Some errors are just small errors that allow the user to move along,
   * but other errors needed to be handled higher up in the hierarchy, that is why errors have to be
   * piped to the outer most shell.
   *
   * @param err the error object to raise to Dash.
   */
  private addError(err: any) {
    let isKnownError = false;
    const knownErrors = [DashError];
    for (let i = 0; i < knownErrors.length; i++) {
      if (err instanceof knownErrors[i]) {
        isKnownError = true;
        break;
      }
    }

    if (!isKnownError) {
      err = new DashError("Unclassified error", err, "UNCLASSIFIED_ERROR");
    }

    this.errors.next(
      produce<DashEvent<Error | undefined>>(this.errors.getValue(), (v) => {
        v.event = err as Error;
        v.type = "change-requested";
      })
    );
  }

  private setRoute(route: string) {
    this.router.next(
      produce<DashEvent<string | undefined>>(this.router.getValue(), (v) => {
        v.event = route;
        v.type = "change-requested";
      })
    );
  }

  private setShellState(state: DashShellState) {
    this.shell.next(
      produce(this.shell.getValue(), (v) => {
        v.event.shell = state;
        v.type = "changed";
      })
    );
  }

  /**
   * Should only be called once after the React Layer has rendered.
   */
  public init() {
    this.appStateLoader
      .load()
      .then((appState) => {
        this.shell.next({
          type: "changed",
          event: appState.shell,
        });
        this.project.next({
          type: "changed",
          event: appState.project,
        });
      })
      .catch((err) => this.addError(err));

    firebase.initializeApp(firebaseConfig);
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        this.log.auth.push({
          user: user,
          state: "logged-in",
          result: getAuthResult(),
        });
        this.auth.next();
      } else {
        logout();
        this.log.auth.push({
          user: null,
          state: "logged-out",
          result: undefined,
        });
        this.auth.next();
      }
    });
  }

  /**
   * Loads data from local persistence layer in to memory.
   *
   * @returns the `TProjectInfo`
   */
  private async loadFromLocalStorage(): Promise<DashProjectInfo> {
    return {
      meta: await DataUtils.loadObjectFromLocalStorage<DashProjectMetaInfo>(
        "current.meta",
        this.appStateLoader.default().project.meta
      ),
      tasks: await DataUtils.loadObjectFromLocalStorage("current.tasks", this.appStateLoader.default().project.tasks),
      workingHours: await DataUtils.loadObjectFromLocalStorage(
        "current.workingHours",
        this.appStateLoader.default().project.workingHours
      ),
      resources: await DataUtils.loadObjectFromLocalStorage(
        "current.resources",
        this.appStateLoader.default().project.resources
      ),
      holidays: await DataUtils.loadObjectFromLocalStorage(
        "current.holidays",
        this.appStateLoader.default().project.holidays
      ),
      events: await DataUtils.loadObjectFromLocalStorage(
        "current.events",
        this.appStateLoader.default().project.events
      ),
    };
  }

  /**
   * Exports data coming from localstorage in to a serialized EJSON string.
   *
   * @returns serialized EJSON of the entire `TProjectInfo`
   */
  private async export(): Promise<string> {
    const data: DashProjectInfo = await this.loadFromLocalStorage();
    return stringify(data);
  }
}
