import { useContext, useEffect, useState } from "react";
import { DashShellInfo } from "../models/DashShellInfo";
import { filter } from "rxjs";
import { AppStateContext } from "..";
import { useHistory } from "react-router";
import { RequireLoginError } from "../errors/RequireLoginError";
import { DashAuthInfo } from "../models/DashAuthInfo";

export type DashShellResult = { shellState: DashShellInfo; authState: DashAuthInfo };

export const useDashShell = (): DashShellResult => {
  const history = useHistory();
  const app = useContext(AppStateContext);
  const [shellState, setDashShellState] = useState<DashShellInfo>(app.shell.getValue().event);
  const [authState, setDashAuthState] = useState<DashAuthInfo>({
    user: undefined,
    state: "loading",
    result: undefined,
  });

  useEffect(() => {
    const authSubscription = app.auth.subscribe((e) => {
      const authstate = app.log.auth.pop();
      if (authstate) {
        setDashAuthState(authstate);
      }
    });

    const errorSubscription = app.errors.pipe(filter((e) => e.type === "change-requested")).subscribe((e) => {
      // TODO: manage what happens to the UI when an error is encountered
      if (e.event) {
        if (e.event instanceof RequireLoginError) {
          history.replace("/login");
        }
      }
    });

    const routerSubscription = app.router.pipe(filter((e) => e.type === "change-requested")).subscribe((e) => {
      if (e.event) {
        history.push(e.event);
      }
    });

    const refreshSubscription = app.shell.pipe(filter((e) => e.type === "changed")).subscribe((e) => {
      setDashShellState(e.event);
    });

    app.init();

    return () => {
      try {
        refreshSubscription.unsubscribe();
      } catch {
        console.log("Error unsubscribing refreshSubscription");
      }

      try {
        authSubscription.unsubscribe();
      } catch {
        console.log("Error unsubscribing authSubscription");
      }

      try {
        errorSubscription.unsubscribe();
      } catch {
        console.log("Error unsubscribing errorSubscription");
      }

      try {
        routerSubscription.unsubscribe();
      } catch {
        console.log("Error unsubscribing routerSubscription");
      }

      try {
        routerSubscription.unsubscribe();
      } catch {
        console.log("Error unsubscribing routerSubscription");
      }
    };
  }, [app, history]); // Empty array ensures that effect is only run on mount

  return { shellState, authState };
};
