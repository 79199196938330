import firebase from "firebase/app";
import "firebase/auth";
import { Component } from "../../models/Dash";
import { StyledFirebaseAuth } from "react-firebaseui";
import { firebaseUiConfig } from "../../consts/firebase";

export const DashFirebaseAuth: Component = () => {
  const config = firebaseUiConfig((some) => {
    return true;
  });

  return (
    <div className="text-center self-center align-middle" key="dash-auth-page">
      <StyledFirebaseAuth key="dash-auth-component" uiConfig={config} firebaseAuth={firebase.auth()} />
    </div>
  );
};
