import { getCircularReplacer } from "../../utils/JsonUtils";
import { VersionInfo } from "../../VersionInfo";
import { IOneDriveOpenResult, IOneDriveSaveOptions, IOneDriveSaveResult } from "./IOneDrive";

export class OneDrive {
  public static save(name: string, type: string = ".json", accessToken: string): Promise<IOneDriveSaveResult> {
    return new Promise((resolve, reject) => {
      const options: IOneDriveSaveOptions = {
        clientId: "88816079-a4ef-4321-9deb-ae06dfc1e19a",
        action: "save",
        sourceInputElementId: "one-drive-upload",
        fileName: name + type,
        openInNewWindow: true,
        nameConflictBehavior: "replace",
        accountSwitchEnabled: true,
        viewType: "all",
        advanced: {
          redirectUri: VersionInfo.baseUrl + "/lib/onedrive/redirect.html",
          accessToken: accessToken,
        },
        progress: (percent) => {
          console.log(`onedrive: upload percent ${percent}`);
        },
        success: (response) => {
          localStorage.setItem("on-save-onedrive", JSON.stringify(response, getCircularReplacer()));
          resolve({ state: "success", response: response });
        },
        cancel: () => {
          resolve({ state: "cancel" });
        },
        error: (error) => {
          reject(error);
        },
      };
      window.OneDrive.save(options);
    });
  }

  public static open(accessToken: string): Promise<IOneDriveOpenResult> {
    return new Promise((resolve, reject) => {
      window.OneDrive.open({
        clientId: "88816079-a4ef-4321-9deb-ae06dfc1e19a",
        action: "download",
        multiSelect: false,
        success: (files) => {
          localStorage.setItem("on-open-onedrive", JSON.stringify(files, getCircularReplacer()));
          resolve({ state: "success", files: files });
        },
        cancel: () => {
          resolve({ state: "cancel" });
        },
        error: (error) => {
          reject(error);
        },
        advanced: {
          filter: "folder,.json",
          redirectUri: VersionInfo.baseUrl + "/lib/onedrive/redirect.html",
          isConsumerAccount: false,
          accessToken: accessToken,
        },
      });
    });
  }
}
