import { ColumnDirective, ColumnsDirective, GridComponent, Inject } from "@syncfusion/ej2-react-grids";
import { useContext, useRef } from "react";
import { GridPrefs } from "../../consts/DashGridSettings";
import { DashProjectInfo } from "../../models/DashProjectInfo";
import produce from "immer";
import { TGridActionCompleteArgs } from "../../models/TGridActionCompleteArgs";
import { DashHolidayInfo } from "../../models/DashHolidayInfo";
import { AppStateContext } from "../..";
import { FC } from "../../models/Dash";

export interface DashHolidayManagerProps {
  height: number;
  width: number;
  project: DashProjectInfo;
}

export const DashHolidayManager: FC<DashHolidayManagerProps> = (props: DashHolidayManagerProps) => {
  const ref = useRef<GridComponent>(null);

  const appState = useContext(AppStateContext);
  const actionComplete = (_: TGridActionCompleteArgs) => {
    if (ref?.current?.currentViewData) {
      appState.project.next(
        produce(appState.project.getValue(), (value) => {
          if (ref?.current?.currentViewData) {
            value.event.holidays = ref?.current?.currentViewData as DashHolidayInfo[];
            value.type = "change-requested";
          }
        })
      );
    }
  };
  const data = props.project.holidays;

  return (
    <GridComponent
      key="dash-holidays-grid"
      id="dash-holidays-grid"
      enableAutoFill={true}
      showColumnMenu={true}
      allowSorting={true}
      allowPdfExport={true}
      allowReordering={true}
      allowResizing={true}
      allowMultiSorting={true}
      showColumnChooser={true}
      enableImmutableMode={true}
      allowFiltering={true}
      filterSettings={GridPrefs.filterSettings}
      allowExcelExport={true}
      height={`${props.height}px`}
      width={`${props.width}px`}
      editSettings={GridPrefs.editOptions}
      toolbar={GridPrefs.toolbarOptions}
      dataSource={data}
      selectionSettings={GridPrefs.selectionSettings}
      ref={ref}
      toolbarClick={(e) => GridPrefs.toolbarClick(ref.current, e)}
      actionBegin={(e) => GridPrefs.actionBegin(e)}
      actionComplete={actionComplete}
    >
      <ColumnsDirective>
        <ColumnDirective headerText="Id" field="id" width="120" textAlign="Right" isPrimaryKey={true} visible={false} />
        <ColumnDirective headerText="Name" field="name" />
        <ColumnDirective
          headerText="Date"
          field="date"
          format="MM/dd/yyyy"
          edit={GridPrefs.dateEditPrams}
          editType="datePickerEdit"
          width="100"
        />
        <ColumnDirective headerText="Country" field="country" width="200" />
        <ColumnDirective headerText="Notes" field="notes" />
      </ColumnsDirective>
      <Inject services={GridPrefs.services} />
    </GridComponent>
  );
};
