import { addDays, getISODay } from "date-fns";

/**
 * Finds the closest dayOfWeek from the current day, including the current day.
 *
 * @param dayOfWeek 7 for Sunday, 1 for Monday etc
 * @param fromDate from a specific date
 * @returns the closest dayOfWeek from the current day, including the current day.
 */
export function getClosestDay(
  dayOfWeek: 7 | 1 | 2 | 3 | 4 | 5 | 6,
  fromDate = new Date()
): Date {
  while (dayOfWeek !== getISODay(fromDate)) fromDate = addDays(fromDate, 1);
  return fromDate;
}

/**
 * Finds the nearest Monday from any weekend.
 * 
 * @param fromDate from a specific date
 * @returns the closest week day from the current day, including the current day.
 */
export function getClosestWeekDay(fromDate = new Date()): Date {
  const a = getISODay(fromDate);
  if (a === 6 || a === 7) {
    return getClosestDay(1, fromDate);
  }
  return fromDate;
}
