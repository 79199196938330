import { GanttComponent } from "@syncfusion/ej2-react-gantt";
import {
  ColumnMenu,
  Edit,
  EditSettingsModel,
  ExcelExport,
  Filter,
  FilterSettingsModel,
  GridComponent,
  Group,
  IEditCell,
  PdfExport,
  Reorder,
  Resize,
  Search,
  SelectionSettingsModel,
  Sort,
  Toolbar,
  ToolbarItems,
} from "@syncfusion/ej2-react-grids";
import { v4 as uuidv4, validate as uuidValidate } from "uuid";
import { TDataArg } from "../models/TDataArg";
import { TIdentifiable } from "../models/TIdentifiable";
import { TGridActionBeginEventArgs } from "../models/TGridActionBeginEventArgs";
import { TClickEventArgs } from "../models/TClickEventArgs";

const editOptions: EditSettingsModel = {
  allowEditing: true,
  allowAdding: true,
  allowDeleting: true,
  allowEditOnDblClick: true,
  allowNextRowEdit: false,
  mode: "Normal",
};

const dateEditPrams: IEditCell = { params: { format: "MM/dd/yyyy" } };

const filterSettings: FilterSettingsModel = { type: "Excel" };

const selectionSettings: SelectionSettingsModel = { type: "Multiple" };

const toolbarOptions: ToolbarItems[] = [
  "Add",
  "Edit",
  "Delete",
  "Update",
  "Cancel",
  "ExcelExport",
  "PdfExport",
  "Search",
];

const services = [Edit, Toolbar, Sort, Group, Filter, Search, ExcelExport, PdfExport, Reorder, Resize, ColumnMenu];

const toolbarClick = (componentRef: GridComponent | GanttComponent | null | undefined, args: TClickEventArgs) => {
  if (args?.item.id?.includes("excelexport")) {
    componentRef?.excelExport();
  } else if (args?.item.id?.includes("pdfexport")) {
    componentRef?.pdfExport();
  }
};

/**
 * This ensures that a primary key uuid is generated for the primary key field `id`. It checks
 * first if there is an existing primary that is valid uuid and then it replaces it if there is
 * none.
 *
 * @param e Grid ActionBeginEventArgs
 */
const actionBegin = (e: TGridActionBeginEventArgs): void => {
  if (e) {
    e.cancel = false;
  }
  if (e?.requestType === "save") {
    const s = e as TDataArg<TIdentifiable>;
    try {
      if (!uuidValidate(s.data.id.toString())) {
        s.data.id = uuidv4();
      }
    } catch {
      s.data.id = uuidv4();
    }
  }
};

export const GridPrefs = {
  toolbarClick,
  actionBegin: actionBegin,
  editOptions,
  dateEditPrams,
  filterSettings,
  selectionSettings,
  toolbarOptions,
  services,
};
