import { INavLink, INavLinkGroup, INavStyles, Nav } from "@fluentui/react/lib/Nav";
import { useHistory } from "react-router-dom";
import styles from "./DashSidebar.module.css";
import React from "react";
import { FC } from "../../models/Dash";

export type DashSidebarProps = {
  width: number;
  height: number;
};

/**
 * ### Renders the Sidebar Tree View.
 *
 * In order to specify the contents per row in the tree, [read more](https://developer.microsoft.com/en-us/fluentui#/controls/web/nav).
 */
export const DashSidebar: FC<DashSidebarProps> = (props: DashSidebarProps) => {
  const history = useHistory();

  const navStyles: Partial<INavStyles> = {
    root: {
      width: props.width,
      height: props.height - 40,
    },
  };

  const _onLinkClick = (ev?: React.MouseEvent<HTMLElement>, item?: INavLink) => {
    ev?.preventDefault();
    if (item?.url) history.push(item.url);
  };

  const navLinkGroups: INavLinkGroup[] = [
    {
      links: [
        {
          name: "Project",
          url: "/",
          key: "dash-sidebar-menu-project",
          isExpanded: true,
          links: [
            {
              name: "Project",
              url: "/",
              key: "dash-sidebar-menu-project-project",
              icon: "TaskManager",
            },
            {
              name: "Tasks",
              url: "/tasks",
              key: "dash-sidebar-menu-project-tasks",
              icon: "BulletedTreeList",
            },
            {
              name: "Gantt",
              url: "/gantt",
              key: "dash-sidebar-menu-project-gantt",
              icon: "TimelineProgress",
            },
            {
              name: "Allocation",
              url: "/allocation",
              key: "dash-sidebar-menu-project-allocation",
              icon: "TimelineDelivery",
            },
          ],
        },
        {
          name: "Folders",
          url: "/",
          key: "dash-sidebar-menu-folder",
          isExpanded: true,
          links: [
            {
              name: "Resources",
              url: "/resources",
              key: "dash-sidebar-menu-data-resources",
              icon: "WorkforceManagement",
            },
            {
              name: "Working Hours",
              url: "/workinghours",
              key: "dash-sidebar-menu-data-workinghours",
              icon: "Clock",
            },
            {
              name: "Holidays",
              url: "/holidays",
              key: "dash-sidebar-menu-data-holidays",
              icon: "CalendarDay",
            },
            {
              name: "Events",
              url: "/events",
              key: "dash-sidebar-menu-data-events",
              icon: "CalendarAgenda",
            },
          ],
        },
        {
          name: "Help",
          url: "/",
          key: "dash-sidebar-menu-help",
          isExpanded: false,
          links: [
            {
              name: "Privacy Policy",
              url: "/privacy-policy",
              key: "dash-sidebar-help-privacy-policy",
              icon: "TextDocumentShared",
            },
            {
              name: "Terms and Conditions",
              url: "/terms-and-conditions",
              key: "dash-sidebar-help-terms-and-conditions",
              icon: "TextDocument",
            },
            {
              name: "Contact",
              url: "/contact-us",
              key: "dash-sidebar-help-contact",
              icon: "ContactInfo",
            },
          ],
        },
      ],
    },
  ];

  return (
    <div
      key="dash-sidebar-inner-container"
      className={styles.sidebar_container}
      style={{
        height: `${props.height}px`,
        width: `${props.width}px`,
      }}
    >
      <div
        key="dash-menu-search-container"
        className={`${styles.sidebar_search} flex flex content-center items-center`}
      >
        <div key="dash-menu-search" className="sidebar-search-box e-input-group e-search" role="search">
          <input key="dash-menu-search-input" className="e-input" name="input" type="search" placeholder="Search" />
          <span
            key="dash-menu-search-cancel-button"
            className="e-input-group-icon e-close-icon e-icons"
            tabIndex={-1}
            title="Cancel Search"
            aria-label="Cancel Search"
          />
          <span
            key="dash-menu-search-button"
            className="e-input-group-icon e-search-icon e-icons"
            tabIndex={-1}
            title="Search"
            aria-label="search"
          />
        </div>
      </div>
      <Nav
        className={`${styles.sidebar_treeview}`}
        key="dash-menu-tree"
        onLinkClick={_onLinkClick}
        selectedKey="key3"
        ariaLabel="Nav basic example"
        styles={navStyles}
        groups={navLinkGroups}
      />
    </div>
  );
};
