import { useContext, useRef } from "react";
import {
  ColumnDirective,
  ColumnMenu,
  ColumnsDirective,
  ContextMenu,
  DayMarkers,
  Edit,
  EventMarkerDirective,
  EventMarkersDirective,
  ExcelExport,
  GanttComponent,
  HolidayDirective,
  HolidaysDirective,
  Inject,
  PdfExport,
  Reorder,
  Resize,
  RowDD,
  Selection,
  Sort,
  SplitterView,
  TaskFieldsModel,
  Toolbar,
  ViewType,
} from "@syncfusion/ej2-react-gantt";
import { DashProjectInfo } from "../../models/DashProjectInfo";
import { TGanttActionCompleteEventArgs } from "../../models/TGanttActionCompleteEventArgs";
import { DashGanttDefaultColumns } from "./DashGanttColumns";
import { GanttSettings, GanttToolbarInitial } from "../../consts/initial";
import { GridPrefs } from "../../consts/DashGridSettings";
import { produce } from "immer";
import { DataUtils } from "../../utils/DataUtils";
import { AppStateContext } from "../..";
import { FC } from "../../models/Dash";

export interface DashGanttProps {
  height: number;
  width: number;
  view: SplitterView;
  type: ViewType;
  project: DashProjectInfo;
}

export const DashGantt: FC<DashGanttProps> = (props: DashGanttProps) => {
  const ref = useRef<GanttComponent>(null);
  const appState = useContext(AppStateContext);
  const settings = GanttSettings;
  const toolbar = GanttToolbarInitial;

  const workingHoursCompat = props.project.workingHours.map((e) => {
    return { from: e.from, to: e.to };
  });

  const markers = props.project.events
    .filter((event) => event.visible)
    .map((event) => {
      return (
        <EventMarkerDirective
          key={event.id}
          day={event.timestamp}
          cssClass="e-custom-event-marker"
          label={`${event.name}`}
        />
      );
    });

  const columns = DashGanttDefaultColumns.default.map((c) => {
    return (
      <ColumnDirective
        key={c.key}
        field={c.field}
        headerText={c.headerText}
        editType={c.editType}
        format={c.format}
        width={c.width}
        isPrimaryKey={c.isPrimaryKey}
        displayAsCheckBox={c.displayAsCheckBox}
        visible={c.visible}
      />
    );
  });

  const actionComplete = (e: TGanttActionCompleteEventArgs) => {
    if (props.type === "ResourceView") return; // dont save on resource view!
    if (!(e?.requestType && ["refresh", "save"].includes(e?.requestType))) return;
    if (ref?.current?.currentViewData) {
      appState.project.next(
        produce(appState.project.getValue(), (value) => {
          if (ref?.current?.currentViewData) {
            value.event.tasks = DataUtils.cleanTasks(ref.current?.flatData);
            value.type = "change-requested";
          }
        })
      );
    }
  };

  return (
    <GanttComponent
      id="dash-gantt"
      key="dash-gantt"
      ref={ref}
      dataSource={props.project.tasks}
      resources={props.project.resources}
      resourceFields={resourceFields}
      taskFields={taskFields}
      allowReordering={true}
      allowRowDragAndDrop={true}
      allowExcelExport={true}
      allowPdfExport={true}
      taskMode="Auto"
      taskType="FixedWork"
      validateManualTasksOnLinking={true}
      height={`${props.height}px`}
      allowSelection={true}
      allowResizing={true}
      allowUnscheduledTasks={true}
      enableContextMenu={true}
      highlightWeekends={true}
      editSettings={settings}
      toolbar={toolbar}
      tooltipSettings={{ showTooltip: false }}
      renderBaseline={true}
      baselineColor="green"
      labelSettings={labelSettings}
      projectStartDate={props.project.meta.projectStartDate}
      projectEndDate={props.project.meta.projectEndDate}
      treeColumnIndex={1}
      enableMultiTaskbar={true}
      showInlineNotes={true}
      showColumnMenu={true}
      selectionSettings={{ type: "Multiple" }}
      toolbarClick={(e) => GridPrefs.toolbarClick(ref.current, e)}
      connectorLineWidth={1}
      splitterSettings={{ view: props.view }}
      viewType={props.type}
      showOverAllocation={props.type === "ResourceView"}
      readOnly={props.type === "ResourceView"}
      dayWorkingTime={workingHoursCompat}
      actionComplete={actionComplete}
    >
      <HolidaysDirective>
        {props.project.holidays.map((holiday, i) => {
          return <HolidayDirective key={i} from={holiday.date} label={holiday.name} cssClass="e-custom-holiday" />;
        })}
      </HolidaysDirective>
      <EventMarkersDirective>{markers}</EventMarkersDirective>
      <Inject
        services={[
          Reorder,
          ColumnMenu,
          Toolbar,
          Edit,
          ContextMenu,
          Selection,
          Sort,
          Resize,
          DayMarkers,
          RowDD,
          ExcelExport,
          PdfExport,
        ]}
      />
      <ColumnsDirective>{columns}</ColumnsDirective>
    </GanttComponent>
  );
};

const taskFields: TaskFieldsModel = {
  id: "id",
  name: "taskName",
  startDate: "startDate",
  endDate: "endDate",
  duration: "duration",
  durationUnit: "durationUnit",
  progress: "progress",
  parentID: "parentID",
  dependency: "dependency",
  baselineStartDate: "baselineStartDate",
  baselineEndDate: "baselineEndDate",
  resourceInfo: "resourceInfo",
  work: "work",
  type: "taskType",
  manual: "taskMode",
  notes: "notes",
};

const resourceFields = {
  id: "id", //resource Id Mapping
  name: "resourceName", //resource Name mapping
  unit: "unit", //resource Unit mapping
  group: "group", //resource Group mapping
};

const labelSettings = {
  rightLabel: "resourceInfo",
};
