import { ColumnDirective, ColumnsDirective, GridComponent, Inject } from "@syncfusion/ej2-react-grids";
import { useContext, useRef } from "react";
import { GridPrefs } from "../../consts/DashGridSettings";
import { DashProjectInfo } from "../../models/DashProjectInfo";
import { TGridActionCompleteArgs } from "../../models/TGridActionCompleteArgs";
import { DashEventInfo } from "../../models/DashEventInfo";
import { AppStateContext } from "../..";
import { FC } from "../../models/Dash";
import produce from "immer";

export interface DashEventManagerProps {
  height: number;
  width: number;
  project: DashProjectInfo;
}

export const DashEventManager: FC<DashEventManagerProps> = (props: DashEventManagerProps) => {
  const ref = useRef<GridComponent>(null);

  const appState = useContext(AppStateContext);
  const actionComplete = (_: TGridActionCompleteArgs) => {
    if (ref?.current?.currentViewData) {
      appState.project.next(
        produce(appState.project.getValue(), (value) => {
          if (ref?.current?.currentViewData) {
            value.event.events = ref?.current?.currentViewData as DashEventInfo[];
            value.type = "change-requested";
          }
        })
      );
    }
  };
  const data = props.project.events;

  return (
    <GridComponent
      key="dash-shell-event-manager"
      id="dash-shell-event-manager"
      enableAutoFill={true}
      showColumnMenu={true}
      allowSorting={true}
      allowPdfExport={true}
      allowReordering={true}
      allowResizing={true}
      allowMultiSorting={true}
      showColumnChooser={true}
      enableImmutableMode={true}
      allowFiltering={true}
      filterSettings={GridPrefs.filterSettings}
      allowExcelExport={true}
      height={`${props.height}px`}
      width={`${props.width}px`}
      editSettings={GridPrefs.editOptions}
      toolbar={GridPrefs.toolbarOptions}
      dataSource={data}
      selectionSettings={GridPrefs.selectionSettings}
      ref={ref}
      toolbarClick={(e) => GridPrefs.toolbarClick(ref.current, e)}
      actionBegin={(e) => GridPrefs.actionBegin(e)}
      actionComplete={actionComplete}
    >
      <ColumnsDirective>
        <ColumnDirective headerText="Id" field="id" width="120" textAlign="Right" isPrimaryKey={true} visible={false} />
        <ColumnDirective
          headerText="Timestamp"
          field="timestamp"
          editType="dateTimePickerEdit"
          format="MM/dd/yyyy hh:mm"
        />
        <ColumnDirective headerText="Name" field="name" />
        <ColumnDirective headerText="Description" field="description" />
        <ColumnDirective headerText="Tag" field="tag" editType="dropDownEdit" />
        <ColumnDirective headerText="Visible" field="visible" editType="booleanEdit" />
      </ColumnsDirective>
      <Inject services={GridPrefs.services} />
    </GridComponent>
  );
};
