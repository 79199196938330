import "firebase/auth";
import { VersionInfo } from "../VersionInfo";
import { getCircularReplacer } from "../utils/JsonUtils";
import { DashAuthResult } from "../models/DashAuthResult";

export const firebaseConfig = {
  apiKey: "AIzaSyDcXKuqH3_gODeZhnvzXXchVbh-gWpg2rE",
  authDomain: "netzon-dash.firebaseapp.com",
  databaseURL: "https://netzon-dash.firebaseio.com",
  projectId: "netzon-dash",
  storageBucket: "netzon-dash.appspot.com",
  messagingSenderId: "659505634485",
  appId: "1:659505634485:web:7954430a3e75a900d632fc",
  measurementId: "G-C41VSH50LR",
};

export type onAuthSuccess = (authResult: any) => boolean;

export const firebaseUiConfig = (onAuthSuccess: onAuthSuccess): firebaseui.auth.Config => {
  return {
    signInFlow: "redirect",
    queryParameterForSignInSuccessUrl: "signInSuccessUrl",
    signInOptions: [
      {
        provider: "microsoft.com",
        scopes: [
          "email",
          "Files.Read",
          "Files.Read.All",
          "Files.Read.Selected",
          "Files.ReadWrite",
          "Files.ReadWrite.All",
          "Files.ReadWrite.AppFolder",
          "Files.ReadWrite.Selected",
          "offline_access",
          "openid",
          "profile",
          "User.Read",
          "User.ReadBasic.All",
        ],
        customParameters: {
          prompt: "consent",
        },
      },
    ],
    tosUrl: `${VersionInfo.baseUrl}/terms-and-conditions`,
    privacyPolicyUrl: `${VersionInfo.baseUrl}/privacy-policy`,
    signInSuccessUrl: `${VersionInfo.baseUrl}`,
    callbacks: {
      signInSuccessWithAuthResult: (authResult: DashAuthResult, redirectUrl?: string) => {
        const authString = JSON.stringify(authResult, getCircularReplacer());
        localStorage.setItem("auth", authString);
        return onAuthSuccess(authResult);
      },
    },
  };
};
