import "./App.css";
import { useWindowSize } from "./hooks/useWindowSize";
import { DashShell } from "./components/dash-shell/DashShell";
import { useDashProject } from "./hooks/useDashProject";
import { useDashShell } from "./hooks/useDashShell";
import { DashOverlay } from "./components/dash-overlay/DashOverlay";
import { Component } from "./models/Dash";

/**
 * This ensure that the Application has sufficient space in the Window.
 *
 * @returns `App` component.
 */
export const App: Component = () => {
  const windowSize = useWindowSize();
  const project = useDashProject();
  const shell = useDashShell();

  if (
    windowSize.height === undefined ||
    windowSize.width === undefined ||
    shell.shellState.shell === "initial-loading" ||
    shell.shellState.shell === "loading"
  ) {
    return <DashOverlay title="Loading" blocking={true} />;
  }

  return <DashShell height={windowSize.height} width={windowSize.width} project={project} shell={shell} />;
};
