import React from "react";
import ReactDOM from "react-dom";
import reportWebVitals from "./reportWebVitals";
import { App } from "./App";
import { initializeIcons } from "@fluentui/react";
import { setAutoFreeze } from "immer";
import { addHours, startOfToday } from "date-fns";
import { DashMenuState } from "./components/dash-menu/DashMenuState";
import { AppStateDefaults } from "./state/AppStateDefaults";
import { AppStateLoader } from "./state/AppStateLoader";
import { AppStateManager } from "./state/AppStateManager";
import { getClosestWeekDay } from "./timeUtils";
import { ImportExportUtils } from "./utils/ImportExportUtils";
import { enableRipple } from "@syncfusion/ej2-base";
import localforage, { INDEXEDDB, LOCALSTORAGE } from "localforage";
import { IOneDrive } from "./libs/onedrive/IOneDrive";
import { BrowserRouter as Router } from "react-router-dom";
import "./index.css";

//#region configure globals (for legacy dependencies)
declare global {
  interface Window {
    OneDrive: IOneDrive;
  }
}

window.OneDrive = window.OneDrive || {};
//#endregion

//#region configure static dependencies of application
localforage.config({
  driver: [INDEXEDDB, LOCALSTORAGE],
  name: "Dash",
});
initializeIcons();
setAutoFreeze(false);
enableRipple(true);
//#endregion

//#region depedency injection
const dashMenuState = new DashMenuState();
const importExportUtils = new ImportExportUtils();
//#endregion

//#region generate global app defaults
const defaults = AppStateDefaults.GetDefaultProject("SampleProject", addHours(getClosestWeekDay(startOfToday()), 9));
//#endregion

//#region create app state loader
const loader = new AppStateLoader(defaults);
//#endregion

export const AppStateInstance = new AppStateManager(dashMenuState, importExportUtils, loader);
export const AppStateContext = React.createContext(AppStateInstance);

ReactDOM.render(
  <React.StrictMode>
    <AppStateContext.Provider value={AppStateInstance}>
      <Router key="dash-shell-router">
        <App />
      </Router>
    </AppStateContext.Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
