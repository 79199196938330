import { useContext, useEffect, useState } from "react";
import { DashProjectInfo } from "../models/DashProjectInfo";
import { filter } from "rxjs";
import { AppStateContext } from "..";

export const useDashProject = (): DashProjectInfo => {
  const appState = useContext(AppStateContext);
  const [dashProject, setDashProject] = useState<DashProjectInfo>(appState.project.getValue().event);

  useEffect(() => {
    const refreshSubscription = appState.project.pipe(filter((e) => e.type === "changed")).subscribe((e) => {
      setDashProject(e.event);
    });

    return () => {
      try {
        refreshSubscription.unsubscribe();
      } catch {
        console.log("Error unsubscribing refreshSubscription");
      }
    };
  }); // Empty array ensures that effect is only run on mount

  return dashProject;
};
