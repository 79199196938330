import "./DashShell.css";
import { useContext } from "react";
import { DashMenu } from "../dash-menu/DashMenu";
import { DashSidebar } from "../dash-sidebar/DashSidebar";
import { DashBody } from "../dash-body/DashBody";
import { Route, Switch } from "react-router-dom";
import { DashProjectInfo } from "../../models/DashProjectInfo";
import { AppStateContext } from "../..";
import { FC } from "../../models/Dash";
import { DashTermsAndConditions } from "../../pages/dash-terms-and-conditions/DashTermsAndConditions";
import { DashPrivacyPolicy } from "../../pages/dash-privacy-policy/DashPrivacyPolicy";
import { DashContactUs } from "../../pages/dash-contact-us/DashContactUs";
import { DashFirebaseAuth } from "../dash-auth/dash-firebase-auth";
import { DashShellResult } from "../../hooks/useDashShell";

export type DashShellProps = {
  width: number;
  height: number;
  project: DashProjectInfo;
  shell: DashShellResult;
};

export const DashShell: FC<DashShellProps> = (props: DashShellProps) => {
  const appState = useContext(AppStateContext);
  const bodyContentWidth = props.shell.shellState.sidebarOpen ? props.width - 180 : props.width;
  const menuHeight = 42;
  const bodyBorder = 2;
  const bodyHeight = props.height - menuHeight;
  const bodyContentHeight = bodyHeight - bodyBorder;

  return (
    <div className="dash-shell" key="dash-shell">
      <Switch key="dash-body-switch">
        <Route path="/login">
          <DashFirebaseAuth />
        </Route>
        <Route path="/terms-and-conditions">
          <DashTermsAndConditions />
        </Route>
        <Route path="/contact-us">
          <DashContactUs />
        </Route>
        <Route path="/privacy-policy">
          <DashPrivacyPolicy />
        </Route>
        <Route path="*">
          <div className="dash-menu" key="dash-menu-container">
            <DashMenu
              key="dash-menu-component"
              project={props.project}
              onToggleSidebar={() => appState.toggleSidebar(!props.shell.shellState.sidebarOpen)}
              authState={props.shell.authState}
            />
          </div>
          <div className="dash-body flex items-stretch">
            {props.shell.shellState.sidebarOpen ? (
              <DashSidebar key="dash-sidebar-container" height={bodyContentHeight} width={180} />
            ) : undefined}

            <div
              key="dash-body-container"
              id="dash-body-container"
              style={{
                height: bodyContentHeight,
                width: bodyContentWidth,
              }}
            >
              <DashBody
                key="dash-body-component"
                height={bodyContentHeight}
                width={bodyContentWidth}
                project={props.project}
              />
            </div>
          </div>
        </Route>
      </Switch>
    </div>
  );
};
