import { Subject } from "rxjs";

export interface MenuClickEvent {
  id: string;
  parent?: string;
}
export class DashMenuState {
  public events = {
    menuClick: new Subject<MenuClickEvent>(),
  };

  public onMenuClick(event: MenuClickEvent) {
    this.events.menuClick.next(event);
  }
}
