import { DataUtils } from "../utils/DataUtils";
import { DashResourceInfo } from "../models/DashResourceInfo";
import { DashEventInfo } from "../models/DashEventInfo";
import { DashWorkingHourInfo } from "../models/DashWorkingHourInfo";
import { DashTaskInfo } from "../models/DashTaskInfo";
import { DashHolidayInfo } from "../models/DashHolidayInfo";
import { DashProjectMetaInfo } from "../models/DashProjectMetaInfo";
import { DashProjectInfo } from "../models/DashProjectInfo";
import { AppStateInfo } from "../models/AppStateInfo";
import { IAppStateLoader } from "../models/IAppStateLoader";
import { DashShellInfo } from "../models/DashShellInfo";
import { DashAuthInfo } from "../models/DashAuthInfo";
import { isMobile } from "react-device-detect";

export class AppStateLoader implements IAppStateLoader {
  constructor(private project: DashProjectInfo) {}

  default(): AppStateInfo {
    const shell: DashShellInfo = {
      sidebarOpen: true,
      shell: "initial-loading",
    };

    const auth: DashAuthInfo = {
      user: null,
      state: "loading",
    };

    return {
      project: this.project,
      shell: shell,
      auth: auth,
    };
  }

  async load(): Promise<AppStateInfo> {
    const project = {
      resources: await DataUtils.loadObjectFromLocalStorage<DashResourceInfo[]>(
        "current.resources",
        this.project.resources
      ),
      events: await DataUtils.loadObjectFromLocalStorage<DashEventInfo[]>("current.events", this.project.events),
      workingHours: await DataUtils.loadObjectFromLocalStorage<DashWorkingHourInfo[]>(
        "current.workingHours",
        this.project.workingHours
      ),
      tasks: await DataUtils.loadObjectFromLocalStorage<DashTaskInfo[]>("current.tasks", this.project.tasks),
      holidays: await DataUtils.loadObjectFromLocalStorage<DashHolidayInfo[]>(
        "current.holidays",
        this.project.holidays
      ),
      meta: await DataUtils.loadObjectFromLocalStorage<DashProjectMetaInfo>("current.meta", this.project.meta),
    };

    // check if sidebar is open or closed
    const dashSidebar = localStorage.getItem("dash-sidebar");
    const dashSidebarOpen = dashSidebar === "open" || (dashSidebar === null && !isMobile);
    const shell: DashShellInfo = {
      sidebarOpen: dashSidebarOpen,
      shell: "ready",
    };

    const auth: DashAuthInfo = {
      user: null,
      state: "loading",
    };

    return {
      project: project,
      shell: shell,
      auth: auth,
    };
  }
}
