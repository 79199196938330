import { Component } from "../../models/Dash";
import { DashReadableContent } from "../../components/dash-readable-content/DashReadableContent";

export const DashContactUs: Component = () => {
  return (
    <DashReadableContent>
      <h1>Contact Us</h1>
      <p>You may reach us via the following channels.</p>
      <p>
        dash@netzontech.com
        <br /> 4F, HQ Tower, Damosa It Park
        <br /> Davao City, 8000
      </p>
    </DashReadableContent>
  );
};
