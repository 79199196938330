import { FC } from "../../models/Dash";
import "./DashOverlay.css";

export type DashOverlayProps = {
  title: string;
  blocking: boolean;
};

export const DashOverlay: FC<DashOverlayProps> = (props: DashOverlayProps) => {
  if (props.blocking)
    return (
      <div key="block-ui-container" className="block-ui-container">
        <div key="block-ui-overlay" className="block-ui-overlay" />
        <div key="block-ui-message-container" className="block-ui-message-container">
          <div key="block-ui-message" className="block-ui-message">
            <h4 key="block-ui-header">{props.title}</h4>
            <div key="block-ui-loading-indicator" className="loading-indicator">
              <svg key="block-ui-indicator" id="indicator" viewBox="0 0 100 100">
                <circle id="circle" cx="50" cy="50" r="45" />
              </svg>
            </div>
          </div>
        </div>
      </div>
    );
  else return <div key="block-ui-container" style={{ display: "none" }}></div>;
};
