import { RequireLoginError } from "../errors/RequireLoginError";
import { IOneDriveSaveResponse } from "../libs/onedrive/IOneDrive";
import { OneDrive } from "../libs/onedrive/OneDrive";
import { getAccessToken } from "./AuthUtils";

export class ImportExportUtils {
  public open(): Promise<string> {
    return new Promise((resolve, reject) => {
      const input = document.createElement("input");
      input.type = "file";
      input.onchange = (_) => {
        if (input?.files && input?.files.length === 1) {
          const files = Array.from(input.files);
          if (files.length !== 1) {
            reject();
            return;
          }
          const file = files[0];
          resolve(file.text());
          return;
        } else {
          reject();
          return;
        }
      };
      const e = document.createEvent("MouseEvents");
      if (document.defaultView === null) return;
      e.initMouseEvent("click", true, false, document.defaultView, 0, 0, 0, 0, 0, false, false, false, false, 0, null);
      input.dispatchEvent(e);
      input.remove();
    });
  }

  public async saveToOneDrive(
    contents: string,
    name: string,
    type: string = ".json"
  ): Promise<IOneDriveSaveResponse | undefined> {
    const accessToken = getAccessToken();
    if (accessToken === undefined) throw new RequireLoginError("microsoft");
    const file = this.transfer(contents, name, type);
    if (file) {
      const result = await OneDrive.save(name, type, accessToken);
      if (result.state === "success") {
        return result.response;
      }
    } else {
      throw new Error('Unable to transfer data from memory to `<input type="file"/>`');
    }
  }

  /**
   * This function transfers a string in to a `File` object inside an `<input type="file"/>`
   * html element.
   * @param contents the content of the file to save in to an `<input type="file"/>` element.
   * @param name the filename part of the file.
   * @param type the file extension part of the file.
   */
  private transfer(contents: string, name: string, type: string = ".json"): HTMLInputElement | undefined {
    const data = new Blob([contents]);
    const filename = name + type;
    const attributes = { type: "application/json", lastModified: new Date().getTime() };
    const file = new File([data], filename, attributes);
    const container = new DataTransfer();
    container.items.add(file);
    const fileInputElement = document.getElementById("one-drive-upload") as HTMLInputElement;
    if (fileInputElement) {
      fileInputElement.files = container.files;
      return fileInputElement;
    }
  }

  /**
   * This function downloads a string in to a file.
   * @param contents the content of the file to save in to an `<input type="file"/>` element.
   * @param name the filename part of the file.
   * @param type the file extension part of the file.
   */
  public saveToFile(contents: string, name: string, type: string = ".json"): void {
    const a = "data:" + type + ";base64," + btoa(contents);
    this.downloadDataUrl(a, name, type);
  }

  public downloadDataUrl(dataUrl: string, name: string, type: string = ".json"): void {
    const a = document.createElement("a");
    a.href = dataUrl;
    a["download"] = name + type;
    const e = document.createEvent("MouseEvents");
    if (document.defaultView === null) return;
    e.initMouseEvent("click", true, false, document.defaultView, 0, 0, 0, 0, 0, false, false, false, false, 0, null);
    a.dispatchEvent(e);
    a.remove();
  }

  public async openOneDrive(): Promise<string> {
    const accessToken = getAccessToken();
    if (accessToken === undefined) throw new RequireLoginError("microsoft");
    const result = await OneDrive.open(accessToken);
    if (result.state === "success" && result.files && result.files.value.length === 1) {
      const fileUri = result.files.value[0]["@microsoft.graph.downloadUrl"];
      const fileFetch = await fetch(fileUri);
      const content = await fileFetch.blob();
      const text = await content.text();
      return text;
    } else {
      throw new Error("There must be at least one and only one selected file.");
    }
  }
}
