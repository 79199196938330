import { EditSettingsModel } from "@syncfusion/ej2-react-gantt";
import { TToolbarInfo } from "../models/TToolbarInfo";

export const GanttToolbarInitial: TToolbarInfo = [
  "Add",
  "Edit",
  "Delete",
  "Cancel",
  "Update",
  "Indent",
  "Outdent",
  "ZoomIn",
  "ZoomToFit",
  "ZoomOut",
  "PrevTimeSpan",
  "NextTimeSpan",
  "ExpandAll",
  "CollapseAll",
  "Search",
  "ExcelExport",
  "PdfExport",
];
export const GanttSettings: EditSettingsModel = {
  allowAdding: true,
  allowEditing: true,
  allowDeleting: true,
  allowTaskbarEditing: true,
  showDeleteConfirmDialog: true,
};
