import { MenuComponent, MenuEventArgs } from "@syncfusion/ej2-react-navigations";
import { MouseEventHandler, useContext, useState } from "react";
import { DashMenuItemInfo } from "../../models/DashMenuItemInfo";
import { useHistory } from "react-router-dom";
import { VersionInfo } from "../../VersionInfo";
import { DashProjectInfo } from "../../models/DashProjectInfo";
import { AppStateContext } from "../..";
import { useRef } from "react";
import { IsValidFileName } from "../../utils/StringUtils";
import { FC } from "../../models/Dash";
import { DashAuthInfo } from "../../models/DashAuthInfo";
import styles from "./DashMenu.module.css";
import produce from "immer";

export interface DashMenuProps {
  onToggleSidebar: MouseEventHandler<HTMLDivElement> | undefined;
  project: DashProjectInfo;
  authState: DashAuthInfo;
}

export const DashMenu: FC<DashMenuProps> = (props: DashMenuProps) => {
  const [editingProjectName, setEditingProjectName] = useState(false);
  const [tempProjectName, setTempProjectName] = useState("");
  const projectNameInput = useRef<HTMLInputElement>(null);

  const appState = useContext(AppStateContext);
  const history = useHistory();

  const data: DashMenuItemInfo[] = [
    { id: "file", text: "File" },
    { id: "open", parentId: "file", text: "Open" },
    { id: "on-open-file", parentId: "open", text: "Open from File" },
    { id: "on-open-onedrive", parentId: "open", text: "Open from OneDrive" },
    { id: "save", parentId: "file", text: "Save" },
    { id: "on-save-file", parentId: "save", text: "Save as File" },
    { id: "on-save-onedrive", parentId: "save", text: "Save to OneDrive" },
    { id: "export-png", parentId: "save", text: "Save as PNG" },
    { id: "share", parentId: "file", text: "Share" },
    { id: "tools", text: "Tools" },
    { id: "merge-import", parentId: "tools", text: "Merge Import" },
    { id: "merge-import-excel", parentId: "merge-import", text: "Excel" },
    { id: "merge-import-jira", parentId: "merge-import", text: "Jira" },
    { id: "merge-import-gitlab", parentId: "merge-import", text: "Gitlab" },
    { id: "merge-import-trello", parentId: "merge-import", text: "Trello" },
    { id: "reset", parentId: "tools", text: "Reset" },
    { id: "reset-project-data-request", parentId: "reset", text: "Project Data" },
  ];

  const menuFields = {
    itemId: "id",
    parentId: "parentId",
    text: "text",
  };

  let projectNameLabel = (
    <div className={styles.menu_item}>
      <ul
        className="dash-e-menu"
        onClick={(_) => {
          setTempProjectName(props.project.meta.name);
          setEditingProjectName(!editingProjectName);
        }}
      >
        <li key="dash-menu-auth-state" className="dash-e-menu-item">
          {props.project.meta.name}
        </li>
      </ul>
    </div>
  );

  if (editingProjectName) {
    projectNameLabel = (
      <input
        ref={projectNameInput}
        onKeyUp={(e) => {
          if (e.key === "Enter" && projectNameInput.current?.value) {
            const value = projectNameInput.current?.value;
            if (!IsValidFileName(value)) {
              setTempProjectName("");
              return;
            }

            const project = produce(appState.project.getValue().event, (draft) => {
              draft.meta.name = value;
            });

            appState.project.next({
              type: "change-requested",
              event: project,
            });

            setEditingProjectName(false);
          } else if (e.key === "Escape") {
            setEditingProjectName(false);
          }
        }}
        onChange={(e) => {
          setTempProjectName(e.target.value);
        }}
        onBlur={(e) => {
          setEditingProjectName(false);
        }}
        inputMode="text"
        value={tempProjectName}
        placeholder={props.project.meta.name}
        className={styles.menu_project_name_input}
      ></input>
    );
  }

  let user = "Anonymous";
  switch (props.authState.state) {
    case "logged-in":
      if (props.authState.user?.displayName) {
        user = props.authState.user?.displayName;
      }
      break;
    case "logged-out":
      user = "Login";
      break;
    case "loading":
      user = "Loading";
      break;
  }

  return (
    <div key="dash-menu-wrapper" id="dash-menu-wrapper" className="dash-menu flex justify-between">
      <div key="dash-menu-left-wrapper" id="dash-menu-left-wrapper" className="dash-menu-left flex">
        <div key="dash-left-container" id="dash-left-container" className="dash-logo-container">
          <div
            key="dash-menu-button-container"
            id="dash-menu-button-container"
            className={`${styles.menu_hamburger_container}`}
            onClick={props.onToggleSidebar}
          >
            <img
              key="dash-menu-button"
              id="dash-menu-button"
              src="menu.png"
              width="24px"
              height="24px"
              className="self-center"
              alt="Side menu toggle button."
            />
          </div>
        </div>
        <MenuComponent
          key="dash-menu"
          cssClass={styles.menu}
          items={data}
          fields={menuFields}
          showItemOnClick={false}
          select={(e: MenuEventArgs | undefined) => {
            if (e?.item?.id) {
              const id = e.item.id;
              const parent = data.find((item) => item.id === id)?.parentId || undefined;
              appState.dashMenuState.onMenuClick({ parent, id });
            }
          }}
        />
      </div>
      <div key="dash-menu-center-wrapper" id="dash-menu-center-wrapper" className={styles.dash_menu_center}>
        {projectNameLabel}
      </div>
      <div key="dash-menu-version-wrapper" id="dash-menu-version-wrapper" className={styles.dash_menu_right}>
        <div key="dash-menu-version-container" id="dash-menu-version-container" className={styles.version_info}>
          <span key="dash-menu-version-name">{VersionInfo.name} </span>
          <span key="dash-menu-version-version">{VersionInfo.version} </span>
          <span key="dash-menu-version-build">b{VersionInfo.build} </span>
        </div>

        <div key="dash-menu-auth-state-wrapper" id="dash-menu-auth-state-wrapper" className={styles.menu_item}>
          <ul
            key="dash-menu-auth-state-container"
            id="dash-menu-auth-state-container"
            className="dash-e-menu"
            onClick={() => {
              if (props.authState.state === "logged-out") {
                history.push("/login");
              }
            }}
          >
            <li key="dash-menu-auth-state" id="dash-menu-auth-state" className="dash-e-menu-item">
              {user}
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};
