import "firebase/auth";
import { DashGantt } from "../dash-gantt/DashGantt";
import { DashHolidayManager } from "../dash-holiday-manager/DashHolidayManager";
import { Route, Switch } from "react-router-dom";
import { DashResourceManager } from "../dash-resource-manager/DashResourceManager";
import { DashWorkingHoursManager } from "../dash-working-hours-manager/DashWorkingHoursManager";
import { DashEventManager } from "../dash-event-manager/DashEventManager";
import { DashProjectInfo } from "../../models/DashProjectInfo";
import { FC } from "../../models/Dash";

export type DashBodyProps = {
  height: number;
  width: number;
  project: DashProjectInfo;
};

export const DashBody: FC<DashBodyProps> = (props: DashBodyProps) => {
  return (
    <Switch key="dash-body-switch">
      <Route exact path="/">
        <DashGantt
          key="dash-project-page"
          height={props.height}
          width={props.width}
          view={"Default"}
          type={"ProjectView"}
          project={props.project}
        />
      </Route>
      <Route path="/gantt">
        <DashGantt
          key="dash-gantt-page"
          height={props.height}
          width={props.width}
          view={"Chart"}
          type={"ProjectView"}
          project={props.project}
        />
      </Route>
      <Route path="/tasks">
        <DashGantt
          key="dash-tasks-page"
          height={props.height}
          width={props.width}
          view={"Grid"}
          type={"ProjectView"}
          project={props.project}
        />
      </Route>
      <Route path="/allocation">
        <DashGantt
          key="dash-tasks-page"
          height={props.height}
          width={props.width}
          view={"Default"}
          type={"ResourceView"}
          project={props.project}
        />
      </Route>
      <Route path="/holidays">
        <DashHolidayManager
          key="dash-holidays-page"
          height={props.height}
          width={props.width}
          project={props.project}
        />
      </Route>
      <Route path="/resources">
        <DashResourceManager
          key="dash-resources-page"
          height={props.height}
          width={props.width}
          project={props.project}
        />
      </Route>
      <Route path="/workinghours">
        <DashWorkingHoursManager
          key="dash-workinghours-page"
          height={props.height}
          width={props.width}
          project={props.project}
        />
      </Route>
      <Route path="/events">
        <DashEventManager key="dash-events-page" height={props.height} width={props.width} project={props.project} />
      </Route>
      <Route path="*">
        <div key="dash-page-not-found-page" className="text-center self-center align-middle">
          <p>Not yet available!</p>
        </div>
      </Route>
    </Switch>
  );
};
