import { IDashVersionInfo } from "./models/DashVersionInfo";

export class DashVersionInfo implements IDashVersionInfo {
  name = process.env.REACT_APP_NAME || "Dash";
  version = process.env.REACT_APP_VERSION || "0.0.1";
  build = process.env.REACT_APP_BUILD || "177";
  baseUrl = process.env.REACT_APP_BASE_URL || "https://dash.netzontech.com";

  public toString(): string {
    return `${this.name} ${this.version} b${this.build}`;
  }
}

export const VersionInfo = new DashVersionInfo();
