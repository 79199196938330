import { TGanttColumnModel } from "../../models/TGanttColumnMode";

export class DashGanttDefaultColumns {
  public static default: TGanttColumnModel[] = [
    {
      field: "id",
      key: "id",
      width: 110,
      isPrimaryKey: true,
      visible: true,
      editType: "defaultEdit",
    },
    {
      field: "taskName",
      key: "taskName",
      headerText: "Task Name",
      width: 185,
      visible: true,
      editType: "defaultEdit",
      isPrimaryKey: false,
    },
    {
      field: "work",
      key: "work",
      headerText: "Effort",
      width: 150,
      visible: true,
      editType: "defaultEdit",
      isPrimaryKey: false,
    },
    {
      field: "resourceInfo",
      key: "resourceInfo",
      headerText: "Resources",
      width: 150,
      visible: true,
      editType: "defaultEdit",
      isPrimaryKey: false,
    },
    {
      field: "duration",
      key: "duration",
      headerText: "Duration (Days)",
      width: 120,
      visible: true,
      editType: "defaultEdit",
      isPrimaryKey: false,
    },
    {
      field: "progress",
      key: "progress",
      headerText: "Progress",
      editType: "numericEdit",
      width: 120,
      visible: true,
      isPrimaryKey: false,
    },
    {
      field: "taskType",
      key: "taskType",
      headerText: "Type",
      width: 130,
      visible: true,
      editType: "defaultEdit",
      isPrimaryKey: false,
    },
    {
      field: "startDate",
      key: "startDate",
      headerText: "Start Date",
      editType: "DateTimePickerEdit",
      format: "MM/dd/yyyy hh:mm",
      width: 110,
      visible: true,
      isPrimaryKey: false,
    },
    {
      field: "endDate",
      key: "endDate",
      headerText: "End Date",
      editType: "DateTimePickerEdit",
      format: "MM/dd/yyyy hh:mm",
      width: 110,
      visible: true,
      isPrimaryKey: false,
    },
    {
      field: "baselineStartDate",
      key: "baselineStartDate",
      headerText: "Planned start time",
      editType: "DateTimePickerEdit",
      format: "MM/dd/yyyy hh:mm",
      width: 110,
      visible: true,
      isPrimaryKey: false,
    },
    {
      field: "baselineEndDate",
      key: "baselineEndDate",
      headerText: "Planned end time",
      editType: "DateTimePickerEdit",
      format: "MM/dd/yyyy hh:mm",
      width: 110,
      visible: true,
      isPrimaryKey: false,
    },
    {
      field: "dependency",
      key: "dependency",
      headerText: "Dependencies",
      width: 80,
      visible: true,
      editType: "defaultEdit",
      isPrimaryKey: false,
    },
    {
      field: "taskMode",
      key: "taskMode",
      headerText: "Manual",
      displayAsCheckBox: true,
      editType: "booleanEdit",
      width: 100,
      visible: true,
      isPrimaryKey: false,
    },
  ];
}
